import { AfterViewInit, Component, ContentChild, ElementRef, Input, QueryList, TemplateRef, ViewChild, ViewChildren, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swiper from 'swiper';
import { register } from 'swiper/element/bundle';

// deprecated

// DOCS ====> https://swiperjs.com/swiper-api
register();
@Component({
  selector: 'ui-swiper',
  styleUrls: ['./ui-swiper.component.scss'],
  template: `
    <!-- <swiper-container #swiper init="false">
      <swiper-slide #swiperSlide *ngFor="let item of items" [lazy]="lazy">
        <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item }"></ng-container>
      </swiper-slide>
    </swiper-container> -->
  `
})
export class SwiperComponent implements AfterViewInit {
  // private readonly $script = inject(SwiperScriptImporterService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;

  @ViewChild('swiper') swiperRef!: ElementRef<HTMLElement>;
  @ViewChildren('swiperSlide') swiperSlideRef!: QueryList<ElementRef<HTMLElement>>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items!: any[];
  @Input() config!: SwiperConfig;
  @Input() lazy!: boolean;

  swiper!: Swiper;
  swiperElement!: HTMLElement;
  loadedRegister = false;
  activetedLoopFix = false;

  ARW = 16;
  ARH = 9;
  minHeight = 0;

  $scriptLoaded = new BehaviorSubject<boolean>(false);

  isScullyRunning = false;
  isScullyGenerated = false;

  constructor() {
    this.isScullyRunning = true || false;
  }

  ngAfterViewInit() {
    this.swiperElement = this.swiperRef.nativeElement;
  }

  initListeners() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.swiperElement.addEventListener('init', ({ detail }: any) => {
      this.swiper = detail[0];
      this.initSwiperListeners();
    });
  }

  initSwiperListeners() {
    this.swiper.on('resize', () => {
      this.setSwiperMinHeight();
    });
    this.swiper.on('breakpoint', () => this.checkIfNeedLoopFix());
  }

  setSwiperMinHeight() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const slideWidth = (this.swiperRef.nativeElement as any).swiper.slides[0].clientWidth;
    this.minHeight = (slideWidth / this.ARW) * this.ARH;
    this.swiperElement.style.minHeight = `${this.minHeight}px`;
  }

  /**
   * Swiper has some limitations when using loop mode. If there are not enough slides, then the loop will
   * show some issues. This method will add duplicates to the end of the slides to fix this issue if needed.
   */
  checkIfNeedLoopFix() {
    if (this.config.loop && !this.isScullyRunning) {
      const items = this.items.length;
      const currentBreakpoint = this.swiper.currentBreakpoint || 0;
      const breakpoints = this.config.breakpoints || [];
      const slidePerView = breakpoints[currentBreakpoint]?.slidesPerView || this.config.slidesPerView;
      if (slidePerView !== 'auto') {
        if (items > slidePerView && Math.ceil(slidePerView) * 2 > items) {
          this.addDuplicatesForLoopFix();
        }
      }
    }
  }

  addDuplicatesForLoopFix() {
    if (this.activetedLoopFix) return;
    this.activetedLoopFix = true;
    const slidesDuplicates = this.swiperSlideRef.toArray().map(slide => `<swiper-slide>${slide.nativeElement.innerHTML}</swiper-slide>`);
    this.swiper.appendSlide(slidesDuplicates);
  }

  /**
   * Not used at the moment.
   */
  removeDuplicatesOfLoopFix() {
    if (!this.activetedLoopFix) return;
    this.activetedLoopFix = false;
    this.items.forEach(() => {
      this.swiper.removeSlide(this.swiper.slides.length - 1);
    });
  }

  createScullyPlaceholder() {
    // const placeholder = document.createElement('div');
    // placeholder.classList.add('scully-swiper-placeholder');
    // const preload = document.createElement('div');
    // preload.classList.add('swiper-lazy-preloader');
    // placeholder.append(preload);
    // this.swiperElement.append(placeholder);
    // this.swiperElement.classList.add('scully-swiper', 'scully-swiper-isRunning');
  }

  loadRegister() {
    if (!this.loadedRegister) {
      register();
      this.loadedRegister = true;
    }
  }

  setAspectRatio(width: number, height: number) {
    this.ARW = width;
    this.ARH = height;
  }

  //

  @Input() set aspectRatio(value: 'string') {
    if (value.includes(':')) {
      const [width, height] = value.split(':').map(v => parseInt(v, 10));
      this.setAspectRatio(width, height);
    } else if (value.includes('x')) {
      const [width, height] = value.split('x').map(v => parseInt(v, 10));
      this.setAspectRatio(width, height);
    } else if (value.includes('/')) {
      const [width, height] = value.split('/').map(v => parseInt(v, 10));
      this.setAspectRatio(width, height);
    } else {
      throw new Error('Invalid aspect ratio format. Use "16:9", "16x9" or "16/9"');
    }
  }
}

export interface SwiperConfig {
  breakpoints?: {
    [key: string | number]: SwiperBreakpointConfig;
  };
  breakpointsBase?: 'window' | 'container';
  centeredSlides?: boolean;
  centeredSlidesBounds?: boolean;
  effect?: 'fade' | 'cube' | 'coverflow' | 'flip';
  initialSlide?: number;
  loop?: boolean;
  virtual?: boolean;
  slidesPerView: number | 'auto';
  spaceBetween?: number;
  injectStyles?: string[];
  observer?: boolean;
}

export interface SwiperBreakpointConfig {
  slidesPerView?: number | 'auto';
  slidesPerGroup?: number;
  spaceBetween?: number;
}
