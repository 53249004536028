import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { register } from 'swiper/element/bundle';
register();

/**
 * This directive is used to initialize the swiper component
 * If you want to use the carousel with half sides you have to enable the centeredSlidesBounds and centeredSlides properties toguether with
 * a slidePerView value that is not an integer ex: 4.5
 * DOCS ====> https://swiperjs.com/swiper-api
 */

@Directive({
  selector: '[ushSwiperElement]'
})
export class SwiperDirective implements AfterViewInit {
  private readonly swiperElement: HTMLElement;
  @Output() swiperInitialized = new EventEmitter();
  @Input() config?: SwiperOptions;

  constructor(private el: ElementRef<HTMLElement>) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    // Initialize Swiper here
    Object.assign(this.swiperElement, this.config);

    // @ts-ignore
    this.swiperElement?.initialize();

    this.swiperInitialized.emit();

    if (this.config?.slidesPerView !== 'auto') {
      const numVisibleElem = Math.ceil(this.config?.slidesPerView || 1);
      const numElem = this.swiperElement.children.length;

      if (numElem >= numVisibleElem && numVisibleElem * 2 > numElem) {
        // this.duplicateSlides(numVisibleElem);
      }
    }

    this.swiperElement.addEventListener('swiperslidechange', event => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const swiper = (event as any).detail[0];
      const numVisibleElem = Math.ceil(swiper.params.slidesPerView);
      const numElem = swiper.slides.length;
      if (this.config?.loop && this.config.centeredSlidesBounds && this.config.centeredSlides) {
        this.removeLazyCenteredHalfsLoading(swiper, swiper.params.slidesPerView, numElem);
      } else {
        if (this.config?.loop == true) {
          // there is a bug with swiper loop mode and lazy loading
          // this is a workaround to fix it, it removes the lazy attribute from the slides when the swiper is looping
          if (
            (swiper.realIndex == numVisibleElem &&
              swiper.previousRealIndex == numVisibleElem + 1 &&
              swiper.activeIndex == 0) ||
            (swiper.realIndex == numElem - numVisibleElem && swiper.previousRealIndex == numElem - numVisibleElem - 1)
          ) {
            this.removeLazyContainers(swiper);
            // updating the config we make sure we dont enter in this if again
            this.config.loop = false;
          }
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeLazyCenteredHalfsLoading(swiper: any, slidesPerView: number, numElem: number) {
    if (this.config?.loop) {
      const numVisibleElem = Math.floor(slidesPerView);
      const middleElem = Math.floor(numVisibleElem / 2);
      if (
        (swiper.previousRealIndex == numVisibleElem - 1 && swiper.realIndex == numVisibleElem) ||
        (swiper.realIndex == numElem - middleElem && swiper.previousRealIndex == numElem - middleElem + 1)
      ) {
        // make the swiper to remove lazy attribute from all the slides and remove from doom the lazy loading elements if they exist
        this.removeLazyContainers(swiper);
        // updating the config we make sure we dont enter in this if again
        this.config.loop = false;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeLazyContainers(swiper: any) {
    // make the swiper to remove lazy attribute from all the slides and remove from doom the lazy loading elements if they exist
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    swiper.slides.forEach((slide: any) => {
      slide.lazy = false;
      const lazyElem = slide.querySelector('.swiper-lazy-preloader');
      if (lazyElem) {
        lazyElem.remove();
      }
    });
  }
}
